<template>
	<div>
		<CustomTableReport
			:total-pages="totalPages"
			:total-items="totalItems"
			:clinics="clinics"
			:is-prep="true"
			:schedules="schedules"
			:series="series"
			:is-mobile="isMobile"
			:mobile-role="mobileRole"
			@search="search"
			@export="exportExcel"
		>
			<template #headers>
				<tr>
					<th
						v-for="(header, index1) in headers"
						:key="`${header.title}-${index1}`"
						scope="col"
						:class="`${header.class}`"
						><span v-if="index1 === 0 || index1 === 13">{{ header.title }}</span>
						<span v-if="index1 > 0 && index1 < 13">{{
							header.title + "-" + formatShortYear(year)
						}}</span></th
					>
				</tr>
			</template>
			<template #body>
				<template v-if="schedules && schedules.length">
					<tr v-for="(item, index2) in schedules" :key="`${index2}`">
						<template v-if="item.data && item.data.length">
							<td scope="row" class="table-no report-prep__clinic"
								><strong>{{ item.name }}</strong></td
							>
							<td
								v-for="(number, index3) in item.data"
								:key="`${index3}`"
								scope="row"
								class="text-break text-pre-wrap"
								>{{ number }}</td
							>
						</template>
					</tr>
					<tr>
						<template>
							<td scope="row" class="table-no"><strong>Tổng</strong></td>
							<td
								v-for="(value, index3) in totalMonths"
								:key="`${index3}`"
								scope="row"
								class="text-break text-pre-wrap"
								>{{ value }}</td
							>
						</template>
					</tr>
				</template>
				<template v-else>
					<tr class="text-center">
						<td colspan="14" class="fit">
							{{ $t("Table.NoData") }}
						</td>
					</tr>
				</template>
			</template>
		</CustomTableReport>
	</div>
</template>

<script>
import {
	GET_CLINIC_LIST,
	GET_REPORT_PREP_LIST,
	GET_REPORT_PREP_EXPORT,
} from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState } = createNamespacedHelpers("report")

export default {
	name: "ReportPREPManagement",

	props: {
		// Support embedded in mobile
		isMobile: {
			type: [Boolean, String],
			default: false,
		},
		token: {
			type: [String],
			default: null,
		},
		mobileRole: {
			type: [String],
			default: null,
		},
	},

	data() {
		return {
			headers: [
				{
					title: this.$t("ReportScheduleForm.Clinics"),
					class: "fit report-prep__clinic",
				},
				{
					title: this.$t("ReportScheduleForm.Jan"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Feb"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Mar"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Apr"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.May"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Jun"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Jul"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Aug"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Sep"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Oct"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Nov"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Dec"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Sum"),
					class: "fit text-center",
				},
			],

			schedules: [],
			totalMonths: [],
			series: [],
			year: new Date().getFullYear(),
		}
	},

	computed: {
		...mapState(["clinics", "totalItems", "totalPages"]),
	},

	created() {
		this.setData()
	},

	methods: {
		...mapActions({
			GET_CLINIC_LIST,
			GET_REPORT_PREP_LIST,
			GET_REPORT_PREP_EXPORT,
		}),

		setData: async function () {
			const data = {}
			if (this.isMobile) data.token = this.token
			await this.GET_CLINIC_LIST(data)
		},

		search: async function (params = {}) {
			this.schedules = []
			this.year = params.year || new Date().getFullYear()

			const data = { ...params }
			if (this.isMobile) data.token = this.token
			const res = await this.GET_REPORT_PREP_LIST(data)
			this.series = res.slice(0, 10)
			this.rebuildReport(res)
		},

		exportExcel: async function (params = {}) {
			const data = { ...params }
			if (this.isMobile) data.token = this.token
			this.GET_REPORT_PREP_EXPORT(data)
		},

		rebuildReport(data) {
			let chartData = []
			if (data && data.length > 0) {
				data.forEach(el => {
					let temp = [],
						_arr = []
					_arr = JSON.parse(JSON.stringify(el.data))
					temp = this.getSum(_arr)
					_arr.push(temp)
					chartData.push({
						name: el.name,
						data: _arr,
					})
				})
			}
			this.schedules = chartData
			if (chartData.length === data.length) this.getTotalMonths(chartData)
		},

		buildArrayChart() {
			let array = []
			for (let i = 0; i < 13; i++) {
				array[i] = 0
			}
			return array
		},

		getTotalMonths(res) {
			let _sum = []

			_sum = this.buildArrayChart()
			res &&
				res.length &&
				res.forEach(el => {
					for (let i = 0; i < 13; i++) {
						let num = _sum[i]
						num += el.data[i] || 0
						_sum[i] = num
					}
				})

			this.totalMonths = _sum
		},

		getSum(array) {
			if (array && array.length > 0) {
				const concat = a => a.reduce((x, y) => x + y)
				const _total = concat(array.map(x => x))
				return _total || 0
			}
		},

		formatShortYear(year) {
			return year.toString().substr(-2)
		},
	},
}
</script>

<style lang="scss" scoped>
.report-prep {
	&__clinic {
		min-width: 120px;
		max-width: 200px;
		white-space: nowrap !important;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

/deep/ .multiselect__option {
	white-space: normal;
}
</style>
